var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"my-second info bgcolor"},[_c('Head',{attrs:{"title":_vm.$t('info.change_two_psd'),"show":true}}),_c('div',{staticClass:"common-box info-box"},[_c('div',{staticClass:"pass-main"},[_c('van-form',{ref:"passform",staticClass:"pass-form",on:{"submit":_vm.onSubmit}},[_c('van-cell-group',{staticClass:"form-box "},[_c('van-field',{attrs:{"type":_vm.pt3,"name":"password","right-icon":_vm.pt3=='password'?'eye':'closed-eye',"label":_vm.$t('info.old_two_psd'),"placeholder":_vm.$t('login.enter')+ _vm.$t('info.old_two_psd'),"rules":[
                      { required: true, message: _vm.$t('login.required_sec_pass') },
                      { pattern:_vm.passPattern,message:_vm.$t('login.secpass_error'),trigger:'onBlur'}
                      ]},on:{"click-right-icon":_vm.changePassType3},model:{value:(_vm.obj.password),callback:function ($$v) {_vm.$set(_vm.obj, "password", $$v)},expression:"obj.password"}}),_c('van-field',{attrs:{"type":_vm.pt2,"name":"newPassword","right-icon":_vm.pt2=='password'?'eye':'closed-eye',"label":_vm.$t('info.new_two_psd'),"placeholder":_vm.$t('login.enter')+ _vm.$t('info.two_psd') +_vm.$t('login.pass_rule'),"rules":[
                      { required: true, message: _vm.$t('login.required_sec_pass') },
                      { pattern:_vm.passPattern,message:_vm.$t('login.secpass_error'),trigger:'onBlur'}
                      ]},on:{"blur":_vm.validatorPass,"click-right-icon":_vm.changePassType2},model:{value:(_vm.obj.newPassword),callback:function ($$v) {_vm.$set(_vm.obj, "newPassword", $$v)},expression:"obj.newPassword"}}),_c('van-field',{attrs:{"type":_vm.pt1,"name":"newPasswordrepeat","right-icon":_vm.pt1=='password'?'eye':'closed-eye',"label":_vm.$t('info.conform_two_psd'),"placeholder":_vm.$t('info.conform_two_psd'),"rules":[{ required: true, message: _vm.$t('login.required_sec_dbpass') },
                          { pattern:_vm.passPattern,message:_vm.$t('login.secpass_error'),trigger:'onBlur'},
                          {
                              validator:_vm.validatordbPass,message:_vm.$t('login.secpass_equal_error'),trigger:'onBlur'
                          }
                      ]},on:{"click-right-icon":_vm.changePassType1},model:{value:(_vm.obj.newPasswordrepeat),callback:function ($$v) {_vm.$set(_vm.obj, "newPasswordrepeat", $$v)},expression:"obj.newPasswordrepeat"}})],1)],1),_c('van-button',{staticClass:"login-btn",on:{"click":_vm.changepass}},[_vm._v(_vm._s(_vm.$t('common.confirm')))])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }